import 'normalize.css';

class SelectHandler {
  constructor({ selectContainer, selectOptionsContainer }) {
    this.selectContainer = selectContainer;
    this.selectOptionsContainer = selectOptionsContainer;
    this.selectOptions = this.selectOptionsContainer.querySelectorAll(
      '.select-options__option button'
    );
    this.selectedOption = this.selectContainer.querySelector(
      'button.selected-option'
    );
    this.hiddenSelect = this.selectContainer.querySelector('select.hidden');
    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.closeDropDown = this.closeDropDown.bind(this);
    this.handleSelectedOption = this.handleSelectedOption.bind(this);

    if (this.selectContainer && this.selectOptionsContainer) {
      this.selectContainer.addEventListener('click', this.toggleDropDown);
      document.body.addEventListener('click', this.closeDropDown);
      this.selectOptions.forEach(el => {
        el.addEventListener('click', this.handleSelectedOption);
      });
    }
  }

  handleSelectedOption(evt) {
    const { target } = evt;

    const buttonContainer = target.parentNode;
    const currentSelected = this.selectContainer.querySelector(
      '.select-options__option.selected'
    );
    const selectedValue = target.getAttribute('data-value');

    this.selectedOption.innerText = selectedValue;

    this.selectContainer.classList.remove('show');
    buttonContainer.classList.add('selected');
    if (currentSelected) {
      currentSelected.classList.remove('selected');
      this.hiddenSelect.value = selectedValue;
    }
  }

  toggleDropDown(evt) {
    const { target } = evt;
    if (!this.selectOptionsContainer.contains(target)) {
      if (this.selectContainer.classList.contains('show')) {
        this.selectContainer.classList.remove('show');
      } else {
        this.selectContainer.classList.add('show');
      }
    }
  }

  closeDropDown(evt) {
    const { target } = evt;
    if (!this.selectContainer.contains(target)) {
      this.selectContainer.classList.remove('show');
    }
  }
}

class PopupHandler {
  constructor({ modalContainer }) {
    this.modalContainer = modalContainer;
    this.popupOpeners = document.querySelectorAll('[data-open-popup]');
    this.handlePopupOpen = this.handlePopupOpen.bind(this);
    this.handlePopupClose = this.handlePopupClose.bind(this);
    this.currentPopupKey = null;
    this.popupOpeners.forEach(el => {
      el.addEventListener('click', this.handlePopupOpen);
    });
  }

  handlePopupOpen(evt) {
    const { currentTarget } = evt;

    this.currentPopupKey = currentTarget.getAttribute('data-open-popup');
    this.currentModalClose = document.querySelector(
      `[data-close-popup=${this.currentPopupKey}]`
    );
    this.currentModal = document.querySelector(
      `[data-popup-key=${this.currentPopupKey}]`
    );
    if (this.currentModal) {
      evt.preventDefault();
      this.modalContainer.classList.add('show');
      this.currentModal.classList.add('modal-active');
      this.currentModalClose.addEventListener('click', this.handlePopupClose);
      document.documentElement.classList.add('lock-scroll');
    }
  }

  handlePopupClose() {
    this.modalContainer.classList.remove('show');
    document.documentElement.classList.remove('lock-scroll');
    this.currentModalClose.removeEventListener('click', this.handlePopupClose);
    setTimeout(() => {
      this.currentModal.classList.remove('modal-active');
    }, 300);
  }
}

class MobileMenu {
  constructor({
    navItemsContainer,
    navBrandContainer,
    openElement,
    closeElement
  }) {
    this.setViewportWidth = this.setViewportWidth.bind(this);
    this.expand = this.expand.bind(this);
    this.close = this.close.bind(this);
    this.container = navItemsContainer;
    this.navBrandContainer = navBrandContainer;
    this.openElement = openElement;
    this.closeElement = closeElement;

    this.viewportWidth = document.body.clientWidth;
    this.isMobile = this.viewportWidth < 992 ? true : false;
    if (this.openElement && this.closeElement) {
      this.openElement.addEventListener('click', this.expand);
      this.closeElement.addEventListener('click', this.close);
      window.addEventListener('resize', this.setViewportWidth);
    }
  }

  setViewportWidth() {
    this.viewportWidth = document.body.clientWidth;
    this.isMobile = this.viewportWidth < 992 ? true : false;
    if (
      this.isMobile == false &&
      this.container.getAttribute('data-expand') == 'true'
    ) {
      this.close();
    }
  }

  close() {
    this.container.setAttribute('data-expand', false);
    this.navBrandContainer.classList.remove('menu-expanded');
    document.documentElement.classList.remove('lock-scroll');
  }

  expand() {
    this.container.setAttribute('data-expand', true);
    this.navBrandContainer.classList.add('menu-expanded');
    document.documentElement.classList.add('lock-scroll');
  }
}

const initializeScripts = () => {
  //IE 11 polyfill for forEach
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }
  const navItemsContainer = document.querySelector('.nav-main__items');
  const openElement = document.querySelector('.hamburger-button');
  const closeElement = document.querySelector('.dropdown-close');
  const navBrandContainer = document.querySelector('.nav-main__brand');
  if (navBrandContainer && closeElement && openElement && navItemsContainer) {
    new MobileMenu({
      navItemsContainer,
      navBrandContainer,
      openElement,
      closeElement
    });
  }

  const modalContainer = document.querySelector('.modal-container');
  if (modalContainer) {
    new PopupHandler({ modalContainer });
  }

  const selectContainer = document.querySelector('.select-caret');
  const selectOptionsContainer = document.querySelector('.select-options');
  if (selectContainer && selectOptionsContainer) {
    new SelectHandler({ selectContainer, selectOptionsContainer });
  }
};

document.addEventListener('DOMContentLoaded', initializeScripts);
